import React from "react";
import { Modal, Button } from "react-bootstrap";
import ModalImage from "../../assets/images/modalimg.jpg";
const WelcomeModal = ({ show, handleClose }) => {
  return (
    <Modal show={show}>
      <Modal.Body>
        <div>
          <img src={ModalImage} alt={Modal - Image} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default WelcomeModal;
