export const SPORTS_DATA = "SPORTS_DATA";
export const ODDS_DATA = "ODDS_DATA";
export const BET_SLIP = "BET_SLIP";
export const BOOKMAKER_DATA = "BOOKMAKER_DATA";

export const BET_SLIP_CONTENT = "BET_SLIP_CONTENT";
export const SET_SESSION_DATA = "SET_SESSION_DATA";
export const SET_SESSION_ODDS = "SET_SESSION_ODDS";

export const BET_MATCHES = "BET_MATCHES";
export const USER_BETS = "USER_BETS";
export const MATCH_BETS = "MATCH_BETS";
export const MATCH_LIABILITY = "MATCH_LIABILITY";
