export const EvolutionGames = {
  AutoRoulette: {
    href: "/casino/evolution/AutoRoulette",
    code: "1000123",
    casino: "evolution",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  LigthingRoullet: {
    href: "/casino/evolution/LigthingRoullet",
    code: "1000092",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  Roulette: {
    href: "/casino/evolution/Roulette",
    code: "1000094",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  TeenPatti01: {
    href: "/casino/ezugi/TeenPatti01",
    code: "227100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/poker/Poker01.png", alt: "" },
  },

  thirtytwoCards: {
    href: "/casino/ezugi/thirtytwoCards",
    code: "228002",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/card/casino057.png", alt: "" },
  },
  Baccarat: {
    href: "/casino/ezugi/Baccarat",
    code: "106",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/baccarat/casino034.png", alt: "" },
  },
  Blackjack: {
    href: "/casino/ezugi/Blackjack",
    code: "201",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },

  Megaball: {
    href: "/casino/evolution/Megaball",
    code: "1000141",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  sicbodice01: {
    href: "/casino/ezugi/sicbodice01",
    code: "228001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },

  CrazyTime: {
    href: "/casino/evolution/CrazyTime",
    code: "1000148",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DoubleBallRoulette: {
    href: "/casino/evolution/DoubleBallRoulette",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DragonTiger1: {
    href: "/casino/evolution/DragonTiger1",
    code: "1000074",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  // supernowa
  // supernowa
  RNG3playerTeenPatti: {
    href: "/casino/worldcasino/RNG3playerTeenPatti",
    code: "V3TP",
    casino: "wco",
    provider: "SN",
    name: "Casino Queen ",
    providerName: "Supernowa",
  },
  rngKingRace: {
    href: "/casino/worldcasino/rngKingRace",
    code: "VCR",
    casino: "wco",
    provider: "SN",
    name: "RNG King Race ",
    providerName: "Supernowa",
  },
  rngKingRace: {
    href: "/casino/worldcasino/rngKingRace",
    code: "VCR",
    casino: "wco",
    provider: "SN",
    name: "RNG King Race ",
    providerName: "Supernowa",
  },
  rngAkbarRomeoWalter: {
    href: "/casino/worldcasino/rngAkbarRomeoWalter",
    code: "VARW",
    casino: "wco",
    provider: "SN",
    name: "RNG Akbhar Romeo Walter",
    providerName: "Supernowa",
  },
  ClassicAB: {
    href: "/casino/worldcasino/ClassicAB",
    code: "AB",
    casino: "wco",
    provider: "SN",
    name: "Classic Andar Bahar",
    providerName: "Supernowa",
  },
  RNGTeenPatti: {
    href: "/casino/worldcasino/RNGTeenPatti",
    code: "VTP",
    casino: "wco",
    provider: "SN",
    name: "RNG TeenPatti",
    providerName: "Supernowa",
  },
  RNGDragonTiger2020: {
    href: "/casino/worldcasino/RNGDragonTiger2020",
    code: "VDT20",
    casino: "wco",
    provider: "SN",
    name: "RNG Dragon Tiger 2020",
    providerName: "Supernowa",
  },
  GoaAndrBahar: {
    href: "/casino/worldcasino/GoaAndrBahar",
    code: "AB2",
    casino: "wco",
    provider: "SN",
    name: "RNG Poker 2020",
    providerName: "Supernowa",
  },
  WorliMataka: {
    href: "/casino/worldcasino/WorliMataka",
    code: "VWM",
    casino: "wco",
    provider: "SN",
    name: "Worli Mataka",
    providerName: "Supernowa",
  },
  Card32Supernowa: {
    href: "/casino/worldcasino/Card32Supernowa",
    code: "C32",
    casino: "wco",
    provider: "SN",
    name: "32 cards",
    providerName: "Supernowa",
  },
  TeenPatti2020Supernowa: {
    href: "/casino/worldcasino/TeenPatti2020Supernowa",
    code: "TP20",
    casino: "wco",
    provider: "SN",
    name: "Teen pati 20-20",
    providerName: "Supernowa",
  },
  AndarBaharSupernowa: {
    href: "/casino/worldcasino/AndarBaharSupernowa",
    code: "ABC",
    casino: "wco",
    provider: "SN",
    name: "Andar bahar",
    providerName: "Supernowa",
  },
  BaccaratSupernowa: {
    href: "/casino/worldcasino/BaccaratSupernowa",
    code: "BAC",
    casino: "wco",
    provider: "SN",
    name: "Baccarat",
    providerName: "Supernowa",
  },
  Lucky7Supernowa: {
    href: "/casino/worldcasino/Lucky7Supernowa",
    code: "UD7",
    casino: "wco",
    provider: "SN",
    name: "Lucky 7",
    providerName: "Supernowa",
  },

  spribe: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "",
      cover: { src: "./images/spribe/Mine.png", alt: "" },
      name: "Mines",
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "",
      cover: { src: "./images/spribe/Goal.png", alt: "" },
      name: "Goal",
    },
    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "",
      cover: { src: "./images/spribe/Dice.png", alt: "" },
      name: "Dice",
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "",
      cover: { src: "./images/spribe/Aviator.png", alt: "" },
      name: "Aviator",
    },
    plinko: {
      href: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "",
      cover: { src: "./images/spribe/Plinko.png", alt: "" },
      name: "Plinko",
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "",
      cover: { src: "./images/spribe/MiniRoulette.png", alt: "" },
      name: "Mini Roulett",
    },
    hilo: {
      href: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "",
      cover: { src: "./images/spribe/Hilo.png", alt: "" },
      name: "Hilo",
    },
  },
};
