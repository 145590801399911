import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Calling from "../assets/images/calling.png";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";
import BITCOIN from "../assets/images/paymentlogo/Bitcoin.png";
import ETHERIUM from "../assets/images/paymentlogo/etherem.png";
import GPAY from "../assets/images/paymentlogo/GooglePay.png";
import PAYPAl from "../assets/images/paymentlogo/PAYPAL.png";
import PAYTM from "../assets/images/paymentlogo/paytm.png";
import PHONEPAY from "../assets/images/paymentlogo/phonepay.png";
import THETER from "../assets/images/paymentlogo/theter.png";
import UPILOGO from "../assets/images/paymentlogo/UPILOGO.png";
import BANKDEPOSIT from "../assets/images/paymentlogo/bankdeposit.png";

import SupernowaImg from "../assets/images/vivogaming.png";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";
import BetIcon from "../assets/svg/BetIcon";

import HomeIcon1 from "../assets/images/icons/headicons/home.png";
import SportsIcon1 from "../assets/images/icons/headicons/soccer.png";
import EzugiIcon1 from "../assets/images/icons/headicons/bet.png";
import EvolutionIcon1 from "../assets/images/icons/headicons/cards.png";
import CashierIcon1 from "../assets/images/icons/headicons/dollar.png";

const Footer = () => {
  const [activePage, setActivePage] = useState("");
  const location = useLocation();

  const footerPages = [
    "home",
    "exchange",
    "casino/evolution",
    "casino/vivo",
    "deposit",
    "gatewaylist",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <div className="container">
        <div className="supernowaProvider">
          <img src={SupernowaImg} alt="supernowa" />
        </div>
        <div className="providers_img">
          <a href="javascript:void(0)" style={{ width: "65px" }}>
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="javascript:void(0)" style={{ width: "80px" }}>
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="javascript:void(0)" style={{ width: "130px" }}>
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="javascript:void(0)" style={{ width: "120px" }}>
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="helpline">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? appDetails?.WHATS_APP_URL
                : "javascript:void(0)"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            <img src={Whatsapp} alt="whatsapp" />{" "}
            <p>{appDetails?.WHATS_APP_URL}</p>
          </a>
          {/* <a href="javascript:void(0)">
            <img src={Calling} alt="calling" /> <p>++91 8888880808</p>
          </a> */}
        </div>

        <div className="footer-links">
          <ul>
            <li>
              <a href="/AboutUss">About Us</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicy ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div class="d-flex justify-content-center flex-wrap mt-2">
          <div className="shadow rounded paymentLogo">
            <img loading="lazy" src={BANKDEPOSIT} alt="Payment_logo" />
          </div>
          <div className="shadow rounded paymentLogo">
            <img src={BITCOIN} alt="Payment_logo" />
          </div>
          <div className="shadow rounded paymentLogo">
            <img src={GPAY} alt="Payment_logo" />
          </div>
          <div className="shadow rounded paymentLogo">
            <img src={PAYTM} alt="Payment_logo" />
          </div>
          <div className="shadow rounded paymentLogo">
            <img src={PHONEPAY} alt="Payment_logo" />
          </div>

          <div className="shadow rounded paymentLogo">
            <img src={UPILOGO} alt="Payment_logo" />
          </div>
          <div className="shadow rounded paymentLogo">
            <img src={PAYPAl} alt="Payment_logo" />
          </div>
          <div className="shadow rounded paymentLogo">
            <img src={ETHERIUM} alt="Payment_logo" />
          </div>
          <div className="shadow rounded paymentLogo">
            <img src={THETER} alt="Payment_logo" />
          </div>
        </div>
        {/* <div class="d-flex justify-content-center mt-2">
          <img
            src={PaymentMod}
            loading="lazy"
            style={{ width: "250px" }}
            alt="Payment Otions"
          />
        </div> */}

        <div class="d-flex justify-content-center mt-3 mb-3">
          <img
            src={Betfair}
            loading="lazy"
            class="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="disclamer-text">
          <p>
            Disclaimer - These games are addictive and for Adults (18+) only.
            Play on your responsibility.
          </p>
        </div>
        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" alt="sewa" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" alt="khalti" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? appDetails?.WHATS_APP_URL
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.FACEBOOK != ""
                    ? appDetails?.FACEBOOK
                    : "javascript:void(0)"
                }
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a className={activePage === "home" ? "active" : ""} href="/home">
                {/* <HomeIcon /> */}
                <img src={HomeIcon1} />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "sports" ? "active" : ""}
                href="/sports"
              >
                {/* <SportsIcon /> */}
                <img src={SportsIcon1} />
                <span>Exchange</span>
              </a>
            </li>
            {/* <li>
              <a href="/sports">
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
            <li>
              <a
                className={activePage === "casino/vivo" ? "active" : ""}
                href={CasinoProviders["vivo"]?.href}
              >
                {/* <GamesIcon /> */}
                <img src={EzugiIcon1} />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "gatewaylist" ? "active" : ""}
                href="/gatewaylist"
              >
                <img src={CashierIcon1} />
                <span>Deposit</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
