export const WordCasinoGames = {
  baccarat: {
    href: "/casino/worldcasino/baccarat",
    code: "BAC",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
  },

  // Avaitor: {
  //   href: "/casino/worldcasino/Avaitor",
  //   code: "ATR",
  //   casino: "wco",
  //   provider: "PG",
  //   homeUrl: "",
  //   cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
  // },

  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      pilnko: {
        href: "/casino/spribe/pilnko",
        code: "pilnko",
        casino: "spribe",
        provider: "pilnko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "miniroulette",
        casino: "spribe",
        provider: "miniroulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hilo",
        casino: "miniroulette",
        provider: "hilo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },

  // supernowa
  RNG3playerTeenPatti: {
    href: "/casino/worldcasino/RNG3playerTeenPatti",
    code: "V3TP",
    casino: "wco",
    provider: "SN",
    name: "Casino Queen ",
    providerName: "Supernowa",
  },
  rngKingRace: {
    href: "/casino/worldcasino/rngKingRace",
    code: "VCR",
    casino: "wco",
    provider: "SN",
    name: "RNG King Race ",
    providerName: "Supernowa",
  },
  rngKingRace: {
    href: "/casino/worldcasino/rngKingRace",
    code: "VCR",
    casino: "wco",
    provider: "SN",
    name: "RNG King Race ",
    providerName: "Supernowa",
  },
  rngAkbarRomeoWalter: {
    href: "/casino/worldcasino/rngAkbarRomeoWalter",
    code: "VARW",
    casino: "wco",
    provider: "SN",
    name: "RNG Akbhar Romeo Walter",
    providerName: "Supernowa",
  },
  ClassicAB: {
    href: "/casino/worldcasino/ClassicAB",
    code: "ABC",
    casino: "wco",
    provider: "SN",
    name: "Classic Andar Bahar",
    providerName: "Supernowa",
  },
  RNGTeenPatti: {
    href: "/casino/worldcasino/RNGTeenPatti",
    code: "VTP",
    casino: "wco",
    provider: "SN",
    name: "RNG TeenPatti",
    providerName: "Supernowa",
  },
  RNGDragonTiger2020: {
    href: "/casino/worldcasino/RNGDragonTiger2020",
    code: "VDT20",
    casino: "wco",
    provider: "SN",
    name: "RNG Dragon Tiger 2020",
    providerName: "Supernowa",
  },
  GoaAndrBahar: {
    href: "/casino/worldcasino/GoaAndrBahar",
    code: "AB2",
    casino: "wco",
    provider: "SN",
    name: "RNG Poker 2020",
    providerName: "Supernowa",
  },
  WorliMataka: {
    href: "/casino/worldcasino/WorliMataka",
    code: "VWM",
    casino: "wco",
    provider: "SN",
    name: "Worli Mataka",
    providerName: "Supernowa",
  },
  Card32Supernowa: {
    href: "/casino/worldcasino/Card32Supernowa",
    code: "C32",
    casino: "wco",
    provider: "SN",
    name: "32 cards",
    providerName: "Supernowa",
  },
  TeenPatti2020Supernowa: {
    href: "/casino/worldcasino/TeenPatti2020Supernowa",
    code: "TP20",
    casino: "wco",
    provider: "SN",
    name: "Teen pati 20-20",
    providerName: "Supernowa",
  },
  AndarBaharSupernowa: {
    href: "/casino/worldcasino/AndarBaharSupernowa",
    code: "AB",
    casino: "wco",
    provider: "SN",
    name: "Andar bahar",
    providerName: "Supernowa",
  },
  BaccaratSupernowa: {
    href: "/casino/worldcasino/BaccaratSupernowa",
    code: "BAC",
    casino: "wco",
    provider: "SN",
    name: "Baccarat",
    providerName: "Supernowa",
  },
  Lucky7Supernowa: {
    href: "/casino/worldcasino/Lucky7Supernowa",
    code: "UD7",
    casino: "wco",
    provider: "SN",
    name: "Lucky 7",
    providerName: "Supernowa",
  },
  JokerSupernowa: {
    href: "/casino/worldcasino/JokerSupernowa",
    code: "VJKR",
    casino: "wco",
    provider: "SN",
    name: "Lucky 7",
    providerName: "Supernowa",
  },
  CasinoQueen2020Supernowa: {
    href: "/casino/worldcasino/CasinoQueen2020Supernowa",
    code: "RCQ20",
    casino: "wco",
    provider: "SN",
    name: "Lucky 7",
    providerName: "Supernowa",
  },
  Cards32Supernowa: {
    href: "/casino/worldcasino/Cards32Supernowa",
    code: "C32",
    casino: "wco",
    provider: "SN",
    name: "Lucky 7",
    providerName: "Supernowa",
  },
  AkbarRomeoWallterSupernowa: {
    href: "/casino/worldcasino/AkbarRomeoWallterSupernowa",
    code: "ARW",
    casino: "wco",
    provider: "SN",
    name: "Lucky 7",
    providerName: "Supernowa",
  },
  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      plinko: {
        href: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
};
