import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import logo from "../assets/svg/logo_new.png";

import HomeIcon1 from "../assets/images/icons/headicons/home.png";
import SportsIcon1 from "../assets/images/icons/headicons/soccer.png";
import EzugiIcon1 from "../assets/images/icons/headicons/bet.png";
import SlotsIcon1 from "../assets/images/icons/headicons/jackpot.png";
import CashierIcon1 from "../assets/images/icons/headicons/dollar.png";

import BotHeadIcon1 from "../assets/images/icons/new/InPlay.png";
import BotHeadIcon2 from "../assets/images/icons/new/cricket.png";
import BotHeadIcon4 from "../assets/images/vivo/icons/baccarat1.png";
import BotHeadIcon5 from "../assets/images/vivo/icons/GalaxyBaccarat3.png";
import BotHeadIcon6 from "../assets/images/vivo/icons/galaxybaccarat2.png";
import BotHeadIcon7 from "../assets/images/vivo/icons/galaxyroulette.png";
import BotHeadIcon123 from "../assets/images/vivo/icons/Frenchroulette.png";

import Logo from "../assets/images/logo.png";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [visible, setVisible] = useState(true);
  return (
    <header className="header beforeheader">
      {visible ? (
        <div className="marqu">
          <marquee>GET BEST BONUS ON EVERY DEPOSIT!!</marquee>
          <span onClick={() => setVisible(!visible)}>
            <AiOutlineClose />
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="top_head">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center header-section">
                <div className="logo">
                  <a href="/">
                    {/* <img src={appDetails?.LOGO_URL} alt="Logo" /> */}
                    <img src={Logo} alt="logo img" />
                  </a>
                </div>
                <div className="middle-part">
                  <Navbar expand="lg">
                    <Container>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <Nav.Link href="/">
                            {/* <HomeIcon />  */}
                            <img src={HomeIcon1} alt="home icon" />
                            Home
                          </Nav.Link>
                          <Nav.Link href="/sign-in" className="blink">
                            {/* <SportsIcon className="" />  */}
                            <img src={SportsIcon1} alt="sports icon" />
                            Exchange
                          </Nav.Link>

                          <Nav.Link href="/sign-in">
                            {/* <GamesIcon />  */}
                            <img src={EzugiIcon1} alt="ezugi icon" />
                            Casino
                          </Nav.Link>
                          <Nav.Link href="/slots">
                            <img src={SlotsIcon1} alt="ezugi icon" />
                            Slots
                          </Nav.Link>
                          <Nav.Link href="/sign-in">
                            {/* <CashierIcon />  */}
                            <img src={CashierIcon1} alt="cash icon" />
                            Cashier
                          </Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>
                <div className="headerRight">
                  <ul className="social">
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.TELEGRAM != ""
                            ? appDetails?.TELEGRAM
                            : "javascript:void(0)"
                        }
                        target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                      >
                        <TelegramIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? appDetails?.WHATS_APP_URL
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <WhatsappIcon />
                      </a>
                    </li>
                  </ul>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    className="button-primary btn_signin"
                  >
                    Login
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/mobile-number");
                    }}
                    className="button-primary btn_signup ms-2"
                  >
                    Register
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom_head">
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/sports");
                    }}
                  >
                    <img src={BotHeadIcon1} alt="bottom head link icon" />
                    <span>Inplay</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/sports");
                    }}
                  >
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Cricket</span>
                  </a>
                </li>

                <li>
                  <a href="/casino/vivo-french-roulette">
                    <img src={BotHeadIcon123} alt="bottom head link icon" />
                    <span>French Roulette</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/vivo-galaxy-roulette">
                    <img src={BotHeadIcon4} alt="bottom head link icon" />
                    <span>Galaxy Roulette</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/vivo-galaxy-baccarat-3">
                    <img src={BotHeadIcon5} alt="bottom head link icon" />
                    <span>Galaxy Baccarat 3</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/vivo-galaxy-baccarat-2">
                    <img src={BotHeadIcon6} alt="bottom head link icon" />
                    <span>Galaxy Baccarat 2</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/vivo-galaxy-baccarat-1">
                    <img src={BotHeadIcon7} alt="bottom head link icon" />
                    <span>Galaxy Baccarat 1</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Login now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Register Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
