import React from "react";

export default function WhatsappIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g id="whatsapp" transform="translate(0.5 0.5)">
        <circle
          id="Ellipse_13"
          data-name="Ellipse 13"
          cx="12"
          cy="12"
          r="12"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
          className="path-stroke"
        />

        <path
          id="Path_1"
          data-name="Path 1"
          d="M22.412,11.09a6.548,6.548,0,0,0-4.693-1.965,6.647,6.647,0,0,0-6.658,6.7,7.029,7.029,0,0,0,.873,3.312l-.946,3.457,3.529-.91a6.494,6.494,0,0,0,3.165.8,6.693,6.693,0,0,0,6.658-6.7A6.606,6.606,0,0,0,22.412,11.09ZM17.719,21.353a5.4,5.4,0,0,1-2.8-.764L14.7,20.48l-2.11.546.546-2.074-.146-.218a5.523,5.523,0,1,1,7.6,1.783A5.3,5.3,0,0,1,17.719,21.353Zm3.2-4.04-.4-.182s-.582-.255-.946-.437c-.036,0-.073-.036-.109-.036a.526.526,0,0,0-.255.073s-.036.036-.546.619a.2.2,0,0,1-.182.109h-.036a.276.276,0,0,1-.146-.073l-.182-.073a3.536,3.536,0,0,1-1.055-.691c-.073-.073-.182-.146-.255-.218a4.065,4.065,0,0,1-.691-.873l-.036-.073c-.036-.036-.036-.073-.073-.146a.273.273,0,0,1,.036-.182s.146-.182.255-.291c.073-.073.109-.182.182-.255a.448.448,0,0,0,.073-.364,14.178,14.178,0,0,0-.582-1.383.421.421,0,0,0-.255-.182h-.4c-.073,0-.146.036-.218.036l-.036.036c-.073.036-.146.109-.218.146-.073.073-.109.146-.182.218a1.856,1.856,0,0,0-.4,1.128,2.16,2.16,0,0,0,.182.837l.036.109a6.063,6.063,0,0,0,1.346,1.856l.146.146a2.2,2.2,0,0,1,.291.291,6.5,6.5,0,0,0,2.619,1.383c.109.036.255.036.364.073h.364a1.349,1.349,0,0,0,.546-.146c.109-.073.182-.073.255-.146l.073-.073c.073-.073.146-.109.218-.182a.964.964,0,0,0,.182-.218,2.086,2.086,0,0,0,.146-.51v-.255A.451.451,0,0,0,20.921,17.313Z"
          transform="translate(-5.62 -3.9)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
