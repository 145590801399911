import React from "react";

export default function TelegramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g id="telegram" transform="translate(0.5 0.5)">
        <circle
          id="Ellipse_13"
          data-name="Ellipse 13"
          cx="12"
          cy="12"
          r="12"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
          className="path-stroke"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M5.505,11.845,17.344,7.28c.549-.2,1.029.134.851.965h0l-2.016,9.5c-.149.673-.549.837-1.109.52L12,16l-1.481,1.426a.774.774,0,0,1-.619.3L10.12,14.6l5.689-5.14c.248-.218-.055-.341-.382-.124L8.4,13.765,5.366,12.82c-.658-.209-.672-.658.139-.975Z"
          transform="translate(-0.135 -0.14)"
          className="path-stroke"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
